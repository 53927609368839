.skelBanner,
.skelBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    padding: 6vmax 6vmax;
}
.skelBox > div:nth-child(1){
    width: 100%;
    height: 5vmax;
    background-color: rgb(220, 220, 220);
    border-radius: 0.4rem;
}
.skelBox > div:nth-child(2){
    width: 100%;
    height: 3vmax;
    background-color: rgb(220, 220, 220);
    border-radius: 0.4rem;
}

.skelBox > div{
    background-image: linear-gradient(120deg, #ececec 50%, #fafafa 60%,#fafafa 61%,#ececec 70%);
    background-size: 200%;
    background-position: 100% 0;
    animation: waves 0.8s linear infinite;
}

/* banner  */
.skelBanner >div:nth-child(1){
    width: 100%;
    height: 20vmax;
    background-color: rgb(220, 220, 220);
    border-radius: 0.4rem;
    background-image: linear-gradient(120deg, #ececec 50%, #fafafa 60%,#fafafa 61%,#ececec 70%);
    background-size: 200%;
    background-position: 100% 0;
    animation: waves 0.8s linear infinite;
}
.skelBanner >div:nth-child(2){
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.skelBanner >div:nth-child(2) > div:nth-child(1){
    width: 10vmax;
    height: 10vmax;
    background-color: rgb(220, 220, 220);
    border-radius: 50%;
    background-image: linear-gradient(120deg, #ececec 50%, #fafafa 60%,#fafafa 61%,#ececec 70%);
    background-size: 200%;
    background-position: 100% 0;
    animation: waves 0.8s linear infinite;

}
.skelBanner >div:nth-child(2) > div:nth-child(2){
    width: 91%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    /* padding: 6vmax 6vmax; */
}
.skelBanner >div:nth-child(2) > div:nth-child(2) > div{
    width: 100%;
    height: 4vmax;
    background-color: rgb(220, 220, 220);
    border-radius: 0.4rem;
    background-image: linear-gradient(120deg, #ececec 50%, #fafafa 60%,#fafafa 61%,#ececec 70%);
    background-size: 200%;
    background-position: 100% 0;
    animation: waves 0.8s linear infinite;

}

@keyframes waves {
    100%{
        background-position: -100% 0 ;
    }
    
}