@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@700&family=Raleway:wght@400;800&display=swap');

*{
    margin: 0;
    padding: 0;
    max-width: 100vw !important;
    /* background-color: green; */
    box-sizing: border-box;
}
body{
    max-width: 100vw;
    /* background-color: green; */
    box-sizing: border-box;
/* background: linear-gradient(var(--bgBody1),var(--bgBody2)); */
background-color: var(--bgBody1);

}
:root{
    --bg1: #21d159;
    --bg1Hov: rgb(32, 128, 88);
    --bg2: #61ff93;
    --bg2Hov:rgb(0, 203, 203);

    --textC1:rgb(49, 49, 49);
    --textC2: white;
    --textBlue: rgb(0, 116, 23);
}


.noUpdate{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7vmax;
}
.noUpdate > p{
    color: rgb(196, 196, 196);
    font: 600 2vmax 'Raleway';
}
